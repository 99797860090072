




























import { Component, Vue, Watch } from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import { Getter, State } from "vuex-class";
import { Address, Contracts } from "@/utils/types";
import { ethers } from "ethers";
import ILeveragePoolABI from "@/contracts/ILeveragePool.json";
import { ILeveragePool } from "@/contracts/ILeveragePool";
import FlatButton from "@/components/FlatButton.vue";

type SimplePosition = {
  positionId: number;
  asset: string;
  amount: string;
};

@Component({
  components: { PageHeader, FlatButton }
})
export default class Toll extends Vue {
  @State contracts!: Contracts;
  @State provider!: ethers.providers.JsonRpcProvider;
  @State signer!: ethers.Signer;

  @Getter isConnected!: boolean;

  private tollablePositions: SimplePosition[] = [];
  private liquidatablePositions: SimplePosition[] = [];
  private tollingPositions: SimplePosition[] = [];
  private terminatable: { [key: number]: boolean } = {};
  $toasted: any;

  @Watch("isConnected")
  priceChange(isConnected: boolean) {
    if (isConnected) {
      this.fetchTollablePositions();
    }
  }

  mounted() {
    // TODO: When deployed on main net this can be removed
    if (this.isConnected) {
      this.fetchTollablePositions();
    }
  }

  private async refresh() {
    console.log("Refreshing...");
    this.tollablePositions = [];
    this.liquidatablePositions = [];
    this.terminatable = {};
    await this.fetchTollablePositions();
    console.log("Refreshing complete");
  }

  // TODO: Rework this whole section to make it work based on updates
  private async fetchTollablePositions(): Promise<void> {
    const leveragePoolAddress = this.contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.provider
    ) as ILeveragePool;

    const totalPositions =
      (await leveragePool.lastCreatedPosition()).toNumber() + 1;

    if (totalPositions <= 0) {
      return;
    }

    for (let i = 0; i < totalPositions; i++) {
      const position = await leveragePool.getPosition(i);
      const ray = ethers.BigNumber.from(10).pow(27);

      if (position.isToller) {
        console.log("Toller positions", position);
        const tollingData = await leveragePool.positionTollingData(
          position.positionID
        );
        const tollingPosition = await leveragePool.getPosition(
          tollingData.tolledPositionID
        );
        this.tollingPositions.push({
          positionId: tollingPosition.positionID.toNumber(),
          asset: tollingPosition.assetBank,
          amount: "" // TODO: tollingPosition.assetAmount.toString()
        });

        const [healthFactor, ltv] = await leveragePool.getPositionHealth(
          tollingPosition.positionID
        );
        if (healthFactor.gt(ray)) {
          this.$set(
            this.terminatable,
            tollingPosition.positionID.toNumber(),
            true
          );
        }
        continue;
      }

      const [healthFactor, ltv] = await leveragePool.getPositionHealth(i);

      console.log("Checking positions", i);
      console.log(
        "  - HF = ",
        healthFactor.div(ray).toString() +
          "." +
          healthFactor.mod(ray).toString()
      );
      console.log(
        "  - LTV = ",
        ltv.div(ray).toString() + "." + ltv.mod(ray).toString()
      );

      if (healthFactor.lte(ray) && ltv.lt(ray)) {
        if (!position.inTolling) {
          this.tollablePositions.push({
            positionId: position.positionID.toNumber(),
            asset: position.assetBank,
            amount: "" // TODO: position.assetAmount.toString()
          });
        }
        continue;
      }

      if (ltv.gte(ray)) {
        this.liquidatablePositions.push({
          positionId: position.positionID.toNumber(),
          asset: position.assetBank,
          amount: "" // TODO: position.assetAmount.toString()
        });
        continue;
      }
    }

    // // List all token transfers *from* address
    // const newPositionFilter = {
    //   address: contracts.PositionManager,
    //   fromBlock: 0, // TODO: Set current block
    //   topics: [
    //     // the name of the event, parentheses containing the data type of each event, no spaces
    //     ethers.utils.id("CreatedPosition(address,uint256)"),
    //     ethers.utils.hexZeroPad(address, 32)
    //   ]
    // };

    // provider.on(newPositionFilter, async (event: any) => {
    //   // New position created
    //   console.log("New positions created!", event);
    // });

    // for (const positionId of positionIds) {
    //   const rawPosition = await leveragePool.getPosition(positionId);

    //   const tokenAddress = rawPosition.assetToken;
    //   const tokenSymbol = addressToSymbol[tokenAddress];

    //   const position: Position = {
    //     positionId: positionId.toNumber(),
    //     asset: tokenSymbol,
    //     amount: parseFloat(
    //       ethers.utils.formatUnits(
    //         rawPosition.assetAmount,
    //         tokenDecimals[tokenSymbol]
    //       )
    //     )
    //   };
    //   commit("addPosition", position);
    // }
  }

  private async toll(position: SimplePosition) {
    const leveragePoolAddress = this.contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.signer
    ) as ILeveragePool;

    const maxUint256 = ethers.BigNumber.from(2)
      .pow(256)
      .sub(1);
    const tx = await leveragePool.toll(
      position.positionId,
      maxUint256,
      maxUint256
    );
    await tx.wait(1);

    // TODO: Do this more efficiently
    this.refresh();

    this.$toasted.success("Started tolling position!").goAway(2500);
  }

  private async canTerminateToll(position: SimplePosition) {
    const res = this.terminatable[position.positionId] ?? false;
    console.log("res", res);
    return res;
  }

  private async terminateToll(position: SimplePosition) {
    const leveragePoolAddress = this.contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.signer
    ) as ILeveragePool;

    const tx = await leveragePool.terminateTollByRestoration(
      position.positionId
    );
    await tx.wait(0);

    // TODO: Figure out which one of these to trigger
    // function terminateTollByRestoration(uint256 positionID) external;
    // function terminateTollByMeltdown(uint256 positionID) external;
    // function terminateTollByFullAcquisition(uint256 positionID) external;
  }
}
